<div *ngIf="showSpinner()" class="centered-popup-container">
  <div class="spinner-container">
    <div class="spinner"></div>
    <img
      src="{{buzzwaveAdminAssetsS3Url()}}/buzzwave-logo-middle.png"
      alt="Overlay Image"
      class="overlay-image"
    />
  </div>
  <mat-progress-bar class="progress-bar" mode="determinate" [value]="progressValue()"></mat-progress-bar>
</div>
