<div class="centered-popup-container">
  <div class="content-container">
    @if (isSuccess()) {
      <header class="header">
        <img src="{{ eventImageUrl() }}" alt="Event Logo" class="logo" />
        <h1>{{ eventName() }}</h1>
      </header>
      @if(showFulfillment()) {
        <h2>Thank you for sharing!</h2>
        <h3>
          <strong>Claim your offer below</strong>
        </h3>
        <span>
          <p class="italics">{{ incentiveText() }}</p>
        </span>
        <button *ngIf="showClaimOfferButton()" class="centered-button" (click)="claimOffer()">
          <span class="button-text">Claim offer</span>
        </button>
      } @else {
        <h3><strong>Thank you for sharing!</strong></h3>
      }
    } @else {
      <h1>Oops! Something went wrong.</h1>
      <p>{{ errorMessage() }}</p>
    }
  </div>
</div>
