import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-share-complete',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './share-complete.component.html',
  styleUrl: './share-complete.component.scss'
})
export class ShareCompleteComponent {
  public isSuccess = signal<boolean>(true);
  public showFulfillment = signal<boolean>(false);
  public showClaimOfferButton = signal<boolean>(false);
  public errorMessage = signal<string>('');
  public eventImageUrl = signal<string>('');
  public eventName = signal<string>('');
  public incentiveText = signal<string>('');
  private incentiveFulfillmentUrl = '';

  constructor(private router: Router, private titleService: Title) {
    const state = this.router.getCurrentNavigation()?.extras.state;

    if (!isNullOrUndefined(state)) {
      if (state!['isSuccess'] === true) {
        this.titleService.setTitle('Share Complete');
        this.isSuccess.set(true);
        this.eventImageUrl.set(state!['eventImageUrl']);
        this.eventName.set(state!['eventName']);
        document.title = this.eventName();
        this.incentiveText.set(state!['incentiveText']);
        this.incentiveFulfillmentUrl = state!['incentiveFulfillmentUrl'];
        if (state!['useIncentive'] === true && this.incentiveFulfillmentUrl.length > 0) {
          this.showFulfillment.set(true);
        }
        if (this.incentiveFulfillmentUrl.length > 0) {
          this.showClaimOfferButton.set(true);
        }
      } else {
        this.titleService.setTitle('Error');
        this.isSuccess.set(false);
        this.errorMessage.set(state!['errorMessage']);
      }
    } else {
      this.isSuccess.set(false);
      this.errorMessage.set('Invalid state information; please try again via the link you were provided.');
    }
  }

  public claimOffer(): void {
    window.location.href = this.incentiveFulfillmentUrl;
  }
}
